import { __decorate } from "tslib";
import { Component, Ref, Vue, Watch } from "@/types";
import { debounce } from "@/helpers/decorators/debounce";
let TableResizeAndDrag = class TableResizeAndDrag extends Vue {
    constructor() {
        super(...arguments);
        this.draggableMonitoringOrder = null;
    }
    /**
     * Инициализировать ресайзеры для таблицы
     */
    mounted() {
        window.addEventListener("load", this.setTableSize);
        window.addEventListener("resize", this.setTableSize);
    }
    /**
     *
     * @param oldVal - старое значение роута
     * @param newVal - новое значение роута
     */
    onUrlChange(oldVal, newVal) {
        if (newVal !== oldVal) {
            this.$nextTick(this.setTableSize);
        }
    }
    /**
     * Динамическая высота таблицы
     */
    setTableSize() {
        if (this.content) {
            if (document.documentElement.clientHeight < 905) {
                this.content.style.height =
                    document.documentElement.clientHeight - 220 + "px";
            }
            else {
                this.content.style.height = "700px";
            }
        }
    }
    beforeDestroy() {
        window.removeEventListener("resize", this.setTableSize);
        window.removeEventListener("load", this.setTableSize);
    }
};
__decorate([
    Ref("content")
], TableResizeAndDrag.prototype, "content", void 0);
__decorate([
    Watch("$route", { immediate: true })
], TableResizeAndDrag.prototype, "onUrlChange", null);
__decorate([
    debounce
], TableResizeAndDrag.prototype, "setTableSize", null);
TableResizeAndDrag = __decorate([
    Component({})
], TableResizeAndDrag);
export default TableResizeAndDrag;
